import type { RouteLocationRaw } from "#vue-router";
import type { TagPageListApiView } from "~~/model/tag-page.model";

export type TagPageListView = TagPageListApiView & {
  _links: {
    page: RouteLocationRaw;
  };
};

export function useTagPageList() {
  return useLazyFetch("/api/tag-pages", {
    transform(data): TagPageListView[] {
      if (!data) return [];

      return data.map((tagPage) => {
        return {
          ...tagPage,
          _links: {
            page: {
              name: "catalog-index-categories-slug",
              params: {
                slug: tagPage.tag.slug,
              },
            },
          },
        };
      });
    },
    default: () => [] as TagPageListView[],
  });
}
